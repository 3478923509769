<template>
  <div v-loading="loading">
    <el-card shadow="never">
      <div slot="header">
        <span class="iconfont icon-MenuPower">&nbsp;权限配置</span>
      </div>
      <div>
        <el-button type="primary" size="small" @click="addPower">新增权限</el-button>
      </div>
      <el-table
        :data="menuList"
        row-key="menuId"
        size="mini"
        default-expand-all
        :tree-props="{children: 'subMenu'}"
        class="powerTable"
      >
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="menuName" label="权限值"></el-table-column>
        <el-table-column prop="path" label="路由地址"></el-table-column>
        <el-table-column prop="icon" label="图标"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="editPower(scope.$index,scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="delMenuPower(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 新增权限 -->
    <el-dialog
      :visible.sync="dialogAddPower"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogAddPower"
    >
      <span slot="title">新增权限</span>
      <el-form
        :model="form"
        ref="form"
        label-width="90px"
        :inline="false"
        size="small"
        class="powerForm"
      >
        <el-form-item
          label="名称:"
          prop="name"
          :rules=" { required: true, message: '请输入权限名称', trigger: 'blur' }"
        >
          <el-input v-model="form.name" placeholder="请输入权限名称"></el-input>
        </el-form-item>
        <el-form-item
          label="权限值:"
          prop="menuName"
          :rules=" { required: true, message: '请输入权限值', trigger: 'blur' }"
        >
          <el-input v-model="form.menuName" placeholder="请输入权限值"></el-input>
        </el-form-item>
        <el-form-item
          label="图标:"
          prop="icon"
          :rules=" { required: true, message: '请输入图标', trigger: 'blur' }"
        >
          <el-input v-model="form.icon" placeholder="请输入图标"></el-input>
        </el-form-item>
        <el-form-item
          label="父级菜单:"
          prop="parentId"
          :rules=" { required: true, message: '请选择父级菜单', trigger: 'change' }"
        >
          <el-cascader
            v-model="form.parentId"
            :options="addOptionsParent"
            :show-all-levels="true"
            :props="{ expandTrigger: 'hover',value:'menuId',label:'name',children:'subMenu',checkStrictly: true}"
            style="width:100%"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="菜单地址:" prop="path">
          <el-input v-model="form.path" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input v-model="form.sort" placeholder="请输入排序号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogAddPower=false" size="small">取消</el-button>
        <el-button type="primary" @click="onSubmitAddMenuPower" size="small">确定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑权限 -->
    <el-dialog :visible.sync="dialogEditPower" :close-on-click-modal="false" width="45%">
      <span slot="title">编辑权限</span>

      <el-form
        :model="editForm"
        ref="editForm"
        label-width="90px"
        :inline="false"
        size="small"
        class="powerForm"
      >
        <el-form-item
          label="名称:"
          prop="name"
          :rules=" { required: true, message: '请输入权限名称', trigger: 'blur' }"
        >
          <el-input v-model="editForm.name" clearable></el-input>
        </el-form-item>
        <el-form-item
          label="权限值:"
          prop="menuName"
          :rules=" { required: true, message: '请输入权限值', trigger: 'blur' }"
        >
          <el-input v-model="editForm.menuName" clearable></el-input>
        </el-form-item>
        <el-form-item
          label="图标:"
          prop="icon"
          :rules=" { required: true, message: '请输入图标', trigger: 'blur' }"
        >
          <el-input v-model="editForm.icon" clearable></el-input>
        </el-form-item>
        <el-form-item label="菜单地址:" prop="path" v-if="isShowPath">
          <el-input v-model="editForm.path"></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input v-model="editForm.sort" placeholder="请输入排序号"></el-input>
        </el-form-item>
        <el-form-item
          label="父级菜单:"
          prop="parentId"
          :rules=" { required: true, message: '请选择父级菜单', trigger: 'change' }"
        >
          <span v-if="isParent">无父级</span>
          <el-cascader
            v-else
            v-model="editForm.parentId"
            :options="editOptionsParent"
            :show-all-levels="true"
            :props="{ expandTrigger: 'hover',value:'menuId',label:'name',children:'subMenu',checkStrictly: true}"
            style="width:100%"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" @click="dialogEditPower = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditMenuPower" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getHomeMenuApi,
  getMenuPowerByParentApi,
  addMenuPowerApi,
  editMenuPowerApi,
  delMenuPowerApi
} from "../../api/data";
import Aes from "../../utils/aes";
export default {
  data() {
    return {
      loading: true,
      menuList: [],
      dialogAddPower: false, //添加窗口
      dialogEditPower: false, //修改窗口
      form: {
        icon: "",
        menuId: "",
        menuName: "",
        name: "",
        parentId: "",
        path: "",
        sort: ""
      },
      editForm: {
        icon: "",
        menuId: "",
        menuName: "",
        name: "",
        parentId: "",
        path: "",
        sort: ""
      },
      addOptionsParent: [], //添加窗口选择父级级联集合
      editOptionsParent: [], //修改窗口选择父级级联集合
      isParent: false, //是否拥有父级
      isShowPath: false //是否显示地址链接
    };
  },
  created() {
    this.initMenu();
  },
  computed: {
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    }
  },
  methods: {
    //初始化权限数据
    initMenu() {
      getHomeMenuApi({
        groupId: this.placesInfo.groupId
      }).then(res => {
        this.menuList = res.data;
        this.loading = false;
      });
    },
    addPower() {
      this.dialogAddPower = true;
      //获取父级菜单
      getMenuPowerByParentApi().then(res => {
        if (res) {
          this.addOptionsParent = [];
          var obj = { menuId: 0, name: "无父级" };
          this.addOptionsParent.push(obj);
          for (let i = 0; i < res.data.length; i++) {
            this.addOptionsParent.push(res.data[i]);
          }
        }
      });
    },
    //打开修改权限窗口
    editPower(index, obj) {
      this.isShowPath = false;
      //判断有没有父级
      if (!obj.parentId) {
        this.isParent = true;
      } else {
        this.isShowPath = true;
        this.isParent = false;
        //获取父级菜单
        getMenuPowerByParentApi({ menuId: obj.menuId }).then(res => {
          if (res) {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].subMenu.length == 0) {
                delete res.data[i].subMenu;
              }
            }
            this.editOptionsParent = res.data;
          }
        });
      }
      this.dialogEditPower = true;
      this.editForm = { ...obj };
    },
    //删除菜单权限
    delMenuPower(obj) {
      this.$confirm("此操作将永久删除该权限, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delMenuPowerApi({ menuId: obj.menuId }).then(res => {
          if (res) this.$message.success(res.msg);
          this.initMenu();
        });
      });
    },
    //提交添加权限
    onSubmitAddMenuPower() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.parentId = this.form.parentId[
            this.form.parentId.length - 1
          ];
          addMenuPowerApi(this.form).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initMenu();
              this.dialogAddPower = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    //添加窗口关闭事件
    closeDialogAddPower() {
      this.$refs["form"].resetFields();
    },
    //提交修改权限
    onSubmitEditMenuPower() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          if (this.editForm.parentId.length != undefined) {
            this.editForm.parentId = this.editForm.parentId[
              this.editForm.parentId.length - 1
            ];
          }
          editMenuPowerApi(this.editForm).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initMenu();
              this.dialogEditPower = false;
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang='scss'>
.powerTable {
  margin-top: 10px;
}
.powerForm {
  padding: 20px;
}
</style>